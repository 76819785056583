import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, signal } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Params, Router, UrlTree } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import {
  CheckoutService as MsCheckoutService,
  OrderHandlerService,
  ProductCatalogService,
  SelfcareService,
} from '@yol-digital/ms-client';
import {
  catchError,
  concatMap,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { AnalyticsService, TrackingCookies } from 'analytics';
import { AuthService, UserService } from 'auth-data-access';
import { CheckoutDataAccessService } from 'checkout-data-access';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutLoadingService } from 'checkout-loading';
import { ContractModalComponent, LoginModalComponent } from 'checkout-modals';
import { CheckoutSessionService } from 'checkout-session';
import { DETAILS_FIELDS_FROM_MS, DetailsFields } from 'checkout-utils';
import { CustomerLookupService } from 'customer-lookup';
import { FeatureFlagService } from 'feature-flag';
import { Promotion } from 'interfaces';
import { LanguageService } from 'language';
import { ModalsService } from 'modal';
import { CmsService } from 'prismic';
import { Product, ProductService } from 'product-shared';
import { ThreatMetrixService } from 'threat-metrix';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import { BrowserService, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';
import DocumentCategoryEnum = MsCheckoutService.DocumentCategoryEnum;
import OrderStatusRes = OrderHandlerService.OrderStatusRes;
import SignStatusRes = MsCheckoutService.SignStatusResp;

export type CheckoutMsStateResponse = MsCheckoutService.CheckStateResp;
export type CheckoutMsResponse = MsCheckoutService.CheckoutDetailsV1Resp;
export interface Promo {
  promotionCode: string;
  backupPromotionApplied: boolean;
  promotion: Promotion;
}

export abstract class CheckoutBaseService {
  protected router = inject(Router);
  protected config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  protected document = inject<Document>(DOCUMENT);
  protected toastService = inject(ToastService);
  protected browserService = inject(BrowserService);
  protected translateService = inject(TranslateService);
  protected authService = inject(AuthService);
  protected userService = inject(UserService);
  protected analyticsService = inject(AnalyticsService);
  protected cmsService = inject(CmsService);
  protected languageService = inject(LanguageService);
  protected checkoutLoadingService = inject(CheckoutLoadingService);
  protected checkoutSessionService = inject(CheckoutSessionService);
  protected checkoutDataAccessService = inject(CheckoutDataAccessService);
  protected threatMetrixService = inject(ThreatMetrixService);
  protected featureFlagService = inject(FeatureFlagService);
  protected checkoutFlowService = inject(CheckoutFlowService);
  protected customerLookupService = inject(CustomerLookupService);
  protected modalsService = inject(ModalsService);
  protected productService = inject(ProductService);
  public backupPromotionApplied = signal(false);
  private _lineCheckRefId: string;

  public get lineCheckRefId(): string {
    return this._lineCheckRefId;
  }

  public set lineCheckRefId(value: string) {
    this._lineCheckRefId = value;
  }

  public productData: ProductCatalogService.CatalogResponse;
  public product: Product;
  public updatedSelfcareData = false;
  public orderId: string;

  public openContract(contract: string) {
    this.modalsService.openDialog(ContractModalComponent, {
      panelClass: 'transparent-modal',
      data: {
        pdf: contract,
      },
    });
  }

  protected setPromotion(
    loadProductRes: ProductCatalogService.CatalogResponse,
    backupOnlinePromotions: PrismicDocument[],
    promotionCodeFromUrl: string,
    defaultPromotion: string
  ) {
    let backupPromotion: { data: { rfe_promotion_code?: string; zira_promotion_code?: string } };
    if (backupOnlinePromotions?.length) {
      backupPromotion = backupOnlinePromotions[0].data.default.find(
        (item: { product: { data: { product_code: string } } }) =>
          item.product.data.product_code === loadProductRes.code
      )?.promotion;
    }

    const ziraEnabledProductClasses = this.featureFlagService
      .getFeatureValue('zira-enabled-product-classes', '')
      .split(',');
    const backupPromotionCode = ziraEnabledProductClasses.includes(loadProductRes.productSpecClass)
      ? backupPromotion?.data?.zira_promotion_code
      : backupPromotion?.data?.rfe_promotion_code;
    const promotionCode = promotionCodeFromUrl ?? defaultPromotion ?? backupPromotionCode;
    const backupPromotionApplied = promotionCodeFromUrl === undefined && !defaultPromotion && !!promotionCode;
    const promo: Promo = {
      promotionCode,
      backupPromotionApplied,
      promotion: null,
    };
    return promotionCode
      ? this.productService.getPCPromotionByCode(promotionCode).pipe(
          map((res: { promotions: ProductCatalogService.PromotionResponse[] }) => {
            promo.promotion = res.promotions.length > 0 ? Promotion.fromMS(res.promotions[0]) : null;
            return promo;
          })
        )
      : of(promo);
  }

  public getLockingPeriod(lockingPeriodStr: string, promotion: Promotion, backupPromotionApplied: boolean): number {
    let lockingPeriod;
    if (backupPromotionApplied && promotion?.lockinLengths?.length) {
      lockingPeriod = Math.min(...promotion.lockinLengths);
    } else if (lockingPeriodStr) {
      lockingPeriod = parseInt(lockingPeriodStr);
    } else {
      lockingPeriod = promotion?.lockinLengths?.length ? Math.min(...promotion.lockinLengths) : 0;
    }
    return lockingPeriod;
  }

  public getBackupPromotion(promotionCodeFromUrl: string, defaultPromotion?: string) {
    return promotionCodeFromUrl || defaultPromotion
      ? of(null)
      : from(
          this.cmsService.getByType('default_online_promotions', undefined, undefined, undefined, [
            'product_box.product_code',
            'promotion.rfe_promotion_code',
            'promotion.zira_promotion_code',
          ])
        );
  }

  public createSessionRequest(trackingCookies: TrackingCookies, params: Params): MsCheckoutService.SessionReq {
    const sessionReq: MsCheckoutService.SessionReq = {
      cookie: '',
      url: window.location.href,
      extraTracking: JSON.stringify(trackingCookies.extraTracking),
    };
    const utm_medium = params['utm_medium'];
    const utm_source = params['utm_source'];
    const utm_campaign = params['utm_campaign'];
    if (utm_medium && utm_source && utm_campaign) {
      sessionReq.utmparams = {
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
      };
    }
    return sessionReq;
  }

  public shouldDoIdCheck(): boolean {
    const checkoutFlowRequiresId = !this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.includes(
      DetailsFields.SCAN_ID_CTA
    );
    if (!checkoutFlowRequiresId) return false;
    return !['inProgress', 'valid'].includes(this.checkoutSessionService.idStatus);
  }

  public addEmail(email: string): Observable<MsCheckoutService.CustomerDetailsResp | { error: string }> {
    return this.checkoutDataAccessService.addEmail(email).pipe(
      mergeMap(() => this.checkoutDataAccessService.checkoutDetailsById()),
      tap((res: CheckoutMsResponse) => {
        this.checkoutSessionService.updateSession(res);
      })
    );
  }

  public addCustomerDetails(
    customerData: Omit<MsCheckoutService.UpdateCustomerDetailsReq, 'checkoutSessionId'>
  ): Observable<MsCheckoutService.CustomerDetailsResp | { error: string }> {
    return this.checkoutDataAccessService.addCustomerDetails(customerData).pipe(
      mergeMap(() => this.checkoutDataAccessService.checkoutDetailsById()),
      tap((res: CheckoutMsResponse) => {
        this.checkoutSessionService.updateSession(res);
        if (customerData.address) {
          this.addAnalyticsEcommerceEvent('add_shipping_info');
        }
      })
    );
  }

  public displayErrorMessage(error: string, firstTranslationString = 'checkout') {
    let message = this.translateService.getTranslation([firstTranslationString, 'errors', error]);
    if (!error || !message) {
      message = this.translateService.getTranslation(['login', 'error', 'generic']);
    }
    this.toastService.add(message, false, 4000);
  }

  public addLineCheck(wishDate: string): Observable<MsCheckoutService.LineCheckResp | { error: string }> {
    return this.checkoutDataAccessService.addLineCheckRef(this.lineCheckRefId, wishDate);
  }

  public addAnalyticsEcommerceEvent(event: string, orderId?: string, userType?: string): void {
    const user_type = userType
      ? userType
      : this.checkoutSessionService.isAuthenticated
        ? 'existing_user'
        : 'new_user_funnel';
    this.analyticsService.addECommerceEvent(
      event,
      this.product,
      this.checkoutSessionService.productPromotion,
      orderId,
      user_type
    );
  }

  public addFieldSubmittedAnalytics(field: string, value?: string) {
    this.addAnalyticsEvent(`${field}_submitted`, value ? { [field]: value } : undefined);
  }

  public addAnalyticsEvent(interaction: string, extraFields?: object): void {
    const data = {
      event: 'checkout_interaction',
      variant: this.product?.item_variant,
      interaction_name: interaction,
      category: this.product?.item_category3,
      user_type: this.checkoutSessionService.isAuthenticated ? 'existing_user' : 'new_user_funnel',
      product_name: this.product?.name,
    };
    Object.assign(data, extraFields);
    this.analyticsService.customEvent(data);
  }

  public checkFormErrorToAddAnalytics(field: string, control: AbstractControl) {
    if (!!control.value && control.invalid) {
      this.addAnalyticsEvent('form_field_error', {
        error_field_name: field,
        error_message: field + ' is invalid',
      });
    }
  }

  public addAccountIdV1(): Observable<{ checkoutSessionId: string } | { error: string }> {
    return this.checkoutDataAccessService.selfcareAddAccountIdV1().pipe(
      switchMap(() => this.checkoutDataAccessService.checkoutDetailsById()),
      tap(res => {
        this.updatedSelfcareData = false;
        this.checkoutSessionService.updateSession(res as CheckoutMsResponse);
      })
    );
  }

  public handleLoggedOutUser() {
    return this.checkoutDataAccessService.resetCustomerDetails().pipe(
      switchMap(() => {
        const product = this.checkoutSessionService.session?.cartData.products[0];
        const code = product?.code;
        const lockingPeriod = product?.lockingPeriod && product?.lockingPeriod;
        const promotionCode = product?.promotion?.code;
        return !this.lineCheckRefId
          ? this.addProduct(code, lockingPeriod, promotionCode).pipe(
              tap(resp => {
                if (!('error' in resp)) {
                  this.threatMetrixService.profile(resp.tmxSessionId);
                }
              })
            )
          : of(null);
      })
    );
  }

  public addProduct(
    code: string,
    lockingPeriod: number,
    promotionCode?: string,
    source?: ProductCatalogService.SourceEnum
  ): Observable<MsCheckoutService.BasketResponse | { error: string }> {
    const products: MsCheckoutService.ProductReq['products'] = [
      {
        code,
        promotionCode,
        lockingPeriod,
        source,
      },
    ];
    return this.checkoutDataAccessService.addProduct(products);
  }

  public handleJsonValidationError(fields: object): string[] {
    const fieldsWithError: string[] = [];
    Object.keys(fields).forEach(key => {
      if (key.includes(DETAILS_FIELDS_FROM_MS.EMAIL)) {
        fieldsWithError.push(DetailsFields.EMAIL);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.CONTACT_PHONE)) {
        fieldsWithError.push(DetailsFields.PHONE);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.FIRST_NAME)) {
        fieldsWithError.push(DetailsFields.FIRST_NAME);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.LAST_NAME)) {
        fieldsWithError.push(DetailsFields.LAST_NAME);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.BIRTHDAY)) {
        fieldsWithError.push(DetailsFields.DATE_OF_BIRTH);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.SHIPPING_ADDRESS_CO)) {
        fieldsWithError.push(`${DetailsFields.SHIPPING_ADDRESS}.diffPostboxName`);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.BILLING_ADDRESS_CO)) {
        fieldsWithError.push(`${DetailsFields.BILLING_ADDRESS}.diffPostboxName`);
      }
      if (key.includes(DETAILS_FIELDS_FROM_MS.OTO_ID)) {
        fieldsWithError.push(DetailsFields.OTO_ID);
      }
    });
    return fieldsWithError;
  }

  public sendScanIdSuccessAnalytics(): void {
    if (this.checkoutSessionService.idScanSuccessful) {
      if (!this.analyticsService.alreadyRegisteredOnDataLayer('scan_id_successful')) {
        this.addAnalyticsEvent('scan_id_successful');
      }
    }
  }

  generateContract() {
    return this.checkoutDataAccessService.generateContract();
  }

  generatePoa() {
    return this.checkoutDataAccessService.generatePoa();
  }

  public getContractStatus(category: MsCheckoutService.DocumentCategoriesEnum): Observable<MsCheckoutService.PdfResp> {
    const callInterval = 3000;
    return timer(0, callInterval).pipe(
      switchMap(() => this.checkoutDataAccessService.getContract(category)),
      filter((res: MsCheckoutService.PdfResp) => res.status === 'ERROR' || res.status === 'READY'),
      take(1),
      map(res => res)
    );
  }

  public getOrderStatus(): Observable<OrderHandlerService.OrderStatusEnum> {
    const callInterval = 3000;
    return timer(0, callInterval).pipe(
      switchMap(() => this.checkoutDataAccessService.retrieveOrderStatus()),
      filter((res: OrderStatusRes) => res.status === 'ORDER_COMPLETED' || res.status === 'ORDER_IN_ERROR'),
      take(1),
      tap((res: OrderStatusRes) => {
        this.orderId = res.orderId;
        this.checkoutSessionService.session.orderId = res.orderId;
        this.checkoutSessionService.session.orderStatus = res.status;
      }),
      map(res => res.status)
    );
  }

  public downloadContract(category: MsCheckoutService.DocumentCategoriesEnum): void {
    this.checkoutDataAccessService.getContract(category).subscribe((res: MsCheckoutService.PdfResp) => {
      this.generateDownloadLink(res.encodedPdf);
    });
  }

  public generateDownloadLink(encodedPdf: string) {
    if (encodedPdf) {
      const link = this.document.createElement('a');
      link.href = 'data:application/pdf;base64,' + encodedPdf;
      link.download = 'contract.pdf';
      link.click();
      this.addAnalyticsEvent('contract_downloaded');
    }
  }

  public getSignStatus(includePoa = false): Observable<SignStatusRes> {
    const callInterval = 3000;
    return timer(0, callInterval).pipe(
      switchMap(() => this.checkoutDataAccessService.getSignStatus()),
      filter((res: SignStatusRes) => {
        return includePoa ? res.signStatus.contract && res.signStatus.poa : res.signStatus.contract;
      }),
      take(1),
      map(res => res)
    );
  }

  generateAndGetContracts(contractAlreadyGenerated = false): Observable<{ contract: string; poaContract?: string }> {
    this.checkoutLoadingService.showSkeletonLoading();
    const generateContractCall$ = contractAlreadyGenerated ? of(null) : this.generateContract();
    const generatePoa = false;
    const getPoaContractCall$ = generatePoa ? this.checkoutDataAccessService.getContract('UNSIGNED_POA') : of(null);
    return generateContractCall$.pipe(
      concatMap(() => forkJoin([this.checkoutDataAccessService.getContract('UNSIGNED_CONTRACT'), getPoaContractCall$])),
      map(([contractPdfResponse, poaPdfResponse]: [MsCheckoutService.PdfResp, MsCheckoutService.PdfResp]) => {
        if (generatePoa) {
          return { contract: contractPdfResponse.encodedPdf, poaContract: poaPdfResponse.encodedPdf };
        }
        return { contract: contractPdfResponse.encodedPdf };
      }),
      catchError(err => {
        if (!err?.wasCaught) {
          this.handleError(err);
        }
        return EMPTY;
      }),
      finalize(() => this.checkoutLoadingService.hideLoading())
    );
  }

  addSign(category: DocumentCategoryEnum, document?: string) {
    return this.checkoutDataAccessService.addSign(category, document);
  }

  public addNewPromoCode(promotionCode: string): Observable<CheckoutMsResponse> {
    const product = this.checkoutSessionService.session?.cartData.products[0];

    const gePCPromotionByCodeCall$: Observable<
      { promotions: ProductCatalogService.PromotionResponse[] } | { error: string }
    > = this.productService.getPCPromotionByCode(promotionCode);

    return gePCPromotionByCodeCall$.pipe(
      switchMap((response: { promotions: ProductCatalogService.PromotionResponse[] }) => {
        if (!response.promotions.length) {
          throw new Error('promotion not found');
        }
        const newPromotion = response.promotions[0];
        const lockingPeriod = newPromotion.availableLockings[0];
        const products = [{ code: product.code, lockingPeriod, promotionCode }];
        return this.checkoutDataAccessService.addProduct(products);
      }),
      switchMap(() => this.checkoutDataAccessService.checkoutDetailsById()),
      tap((res: CheckoutMsResponse) => {
        this.checkoutSessionService.updateSession(res);
      })
    );
  }

  protected abstract handleSubmitOrderErrors(err: HttpErrorResponse): Observable<never>;

  public updateAddressDetails(
    customerAddress: MsCheckoutService.UpdateDetailsReq
  ): Observable<{ checkoutSessionId: string } | { error: string }> {
    return this.checkoutDataAccessService.updateAddressDetails(customerAddress).pipe(
      tap(() => {
        this.addAnalyticsEcommerceEvent('add_shipping_info');
      })
    );
  }

  public openLoginModal(data?: object) {
    this.addAnalyticsEvent('checkout_login_popup');
    const dialogRef = this.modalsService.openDialog(LoginModalComponent, {
      data,
    });
    this.checkoutFlowService.afterLoginModalClosed(dialogRef);
    return dialogRef;
  }

  public checkForUserSessionExpired() {
    if (!this.checkoutSessionService.isAuthenticated) {
      this.openLoginModal({ sessionExpired: true, disableMfaOnboarding: true });

      return of(true);
    }
    return of(false);
  }

  public abstract handleConfirmCustomerDetailsError(err: HttpErrorResponse): Observable<never>;

  public abstract startSession(productCode: string, params: Params): Observable<UrlTree | boolean>;
  public abstract handleError(err: HttpErrorResponse): Observable<never>;
  public abstract loadSession(
    checkoutSessionId: string,
    routePath: string,
    callHideLoadingAtTheEnd?: boolean
  ): Observable<CheckoutMsResponse>;

  public abstract updateContactDetails(data: { contactNumber: string }): Observable<void | {
    error: string;
  }>;

  public abstract updateUserEmail(email?: string): Observable<
    | {
        refId?: string | undefined;
      }
    | {
        error: string;
      }
  >;

  public abstract updateUserBillingAddress(data: SelfcareService.UpdateBillingAddressReq): Observable<void | {
    error: string;
  }>;

  public abstract logout(redirectUrl?: string): void;

  public abstract finishOrder(phoneNumberActiveTab?: number): void;
}
