import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterOutlet, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CanComponentDeactivate, CustomerGuard } from '../../route-guards/customer.guard';

@Component({
  selector: 'app-dummy',
  template: `<router-outlet></router-outlet>`,
  imports: [RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyComponent implements CanComponentDeactivate {
  private customerGuardService = inject(CustomerGuard);

  public canDeactivate(
    currentRoute?: ActivatedRouteSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.customerGuardService.leaveExistingCustomerConfirmation(nextState);
  }
}
