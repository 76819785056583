import { Injectable, signal } from '@angular/core';
import { CheckoutService as MsCheckoutService, SelfcareService } from '@yol-digital/ms-client';
import { Promotion } from 'interfaces';

type CheckoutMsResponse = MsCheckoutService.CheckoutDetailsV1Resp;
type SelfcareAccountResponse = SelfcareService.AccountResp;
@Injectable({
  providedIn: 'root',
})
export class CheckoutSessionService {
  public newPromoCodeApplied = signal<string>(null);
  private _session: CheckoutMsResponse | undefined;
  private _account: SelfcareAccountResponse;

  public get account(): SelfcareAccountResponse {
    return this._account;
  }

  public get isAuthenticated() {
    return !!this._account?.accountId;
  }

  public get session(): CheckoutMsResponse | undefined {
    return this._session;
  }

  public setNewSession(session: Partial<CheckoutMsResponse>) {
    this._session = { ...(<CheckoutMsResponse>{}), ...session };
  }

  public updateSession(session: Partial<CheckoutMsResponse>): void {
    this._session = { ...this._session, ...session };
  }

  public updateAccount(account: Partial<SelfcareAccountResponse>): void {
    this._account = { ...this._account, ...account };
  }

  public resetAccount(): void {
    this._account = <SelfcareAccountResponse>{};
  }

  public get sessionId() {
    return this._session?.checkoutSessionId;
  }

  public get orderId() {
    return this._session?.orderId;
  }

  public get orderStatus() {
    return this._session?.orderStatus;
  }

  public get billingAddress() {
    if (this.isAuthenticated) {
      const selfcareBillingAddress = this._account?.billingAddress;
      return {
        postCode: selfcareBillingAddress?.zip,
        city: selfcareBillingAddress?.city,
        streetName: selfcareBillingAddress?.street,
        streetNumber: selfcareBillingAddress?.streetNumber,
        co: selfcareBillingAddress?.co,
      };
    }
    return this._session?.billingCustomerDetails?.billingAddress;
  }

  public get personalDetails() {
    if (this.isAuthenticated) {
      const accountDetails = this._account?.ownerDetails;
      return {
        nationality: accountDetails?.nationality?.iso3,
        birthday: accountDetails?.dob,
        status: accountDetails?.personalId?.status,
        firstName: accountDetails?.firstName,
        title: accountDetails?.title,
        lastName: accountDetails?.lastName,
        idType: accountDetails?.personalId?.typeId,
        idNumber: accountDetails?.personalId?.id,
        expiryDate: accountDetails?.personalId?.expireDate,
        contactDetail: {
          email: accountDetails?.email,
          phoneNumber: accountDetails?.contactNumber,
        },
      };
    }
    return this._session?.billingCustomerDetails?.personalDetails;
  }

  public get existingCustomer() {
    return !!this._session?.billingCustomerDetails?.accountId;
  }

  public get contactEmail() {
    return this.personalDetails?.contactDetail?.email;
  }

  public get contactPhoneNumber() {
    return this.personalDetails?.contactDetail?.phoneNumber;
  }

  public get firstName() {
    return this.personalDetails?.firstName;
  }

  public get lastName() {
    return this.personalDetails?.lastName;
  }

  public get idDocument() {
    const documentDetails =
      this._session?.billingCustomerDetails?.personalIdDocument?.documentDetails ?? {
        ...this._account?.ownerDetails?.personalId,
        nationality: this._account?.ownerDetails?.nationality?.iso3,
      } ??
      undefined;
    return { ...this._session?.billingCustomerDetails?.personalIdDocument, documentDetails };
  }

  public get idStatus() {
    return this.idDocument?.status;
  }

  public get idScanSuccessful() {
    return this.idDocument?.scanStatus === 'success';
  }

  public get idFastActivation() {
    return this.idDocument?.fastActivation;
  }

  private get product() {
    return this._session?.cartData?.products[0];
  }

  public get productSpecClass() {
    return this.product?.productSpecClass;
  }

  public get productSpecCategory() {
    return this.product?.productSpecCategory;
  }

  public get productSpecFamily() {
    return this.product?.productSpecFamily;
  }

  public get monthlyFee() {
    return this.product?.monthlyFee;
  }

  public get discount() {
    return this.product?.promotion?.discount;
  }

  public get activationFee() {
    return this.product?.activationFee;
  }

  public get activationFeeDiscount() {
    return this.product?.promotion?.activationFeeDiscount;
  }

  public get lockingPeriod() {
    return this.product?.lockingPeriod;
  }

  public get otoIdState() {
    return this.product?.lineCheckDetails?.otoIdState;
  }

  public get installationAddress() {
    return this.product?.lineCheckDetails?.installationAddress;
  }

  public get wishDate() {
    return this.product?.lineCheckDetails?.wishDate;
  }

  public get lineCheckQualifiedBy() {
    return this.product?.lineCheckDetails?.qualifiedBy;
  }

  public get shippingAddress() {
    return this.product?.shippingAddress;
  }

  public get productCode() {
    return this.product?.code;
  }

  public get promotion() {
    return this.product?.promotion;
  }

  public get productPromotion() {
    return (
      this.promotion &&
      new Promotion(
        this.promotion.id,
        this.promotion.code,
        this.promotion.activationFeeDiscount,
        this.promotion.discount,
        this.promotion.duration,
        this.promotion.displayName
      )
    );
  }

  public get eSim() {
    return this.product?.phoneNumberDetails?.esim;
  }

  public get phoneNumberOrderType() {
    return this.product?.phoneNumberDetails?.orderType;
  }

  public get msisdn() {
    return this.product?.phoneNumberDetails?.msisdn;
  }

  public get isMBB() {
    return (
      (this.productSpecClass === 'POSTPAID' && this.productSpecFamily === 'INTERNET') || this.productSpecClass === 'MBB'
    );
  }
}
