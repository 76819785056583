import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { SvgComponent } from 'icon';
import { ModalComponent } from 'modal';
import { ButtonComponent } from 'sakura-ui';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'app-leave-existing-customer-modal',
  imports: [ModalComponent, ButtonComponent, SvgComponent, TranslatePipe, DynamicHooksComponent],
  templateUrl: './leave-existing-customer-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveExistingCustomerModalComponent {
  readonly dialogRef = inject(MatDialogRef<LeaveExistingCustomerModalComponent>);

  closeDialog(leave?: boolean) {
    this.dialogRef.close(leave);
  }
}
