import { DetailsFields } from 'checkout-utils';

interface CheckoutStep {
  path: 'details' | 'phone-number' | 'summary' | 'confirmation' | 'contract-signature';
  substepsQty: number;
  substepsQtyLoggedIn?: number;
}

export enum InformationCardsEnum {
  CANCEL_PREVIOUS_CONTRACT = 'cancel_previous_contract',
  USE_YOUR_TV_LOGIN = 'use_your_tv_login',
  SET_YOUR_OTO_ID_AVAILABLE = 'set_your_oto_id_available',
  SET_YOUR_OTO_ID_UNAVAILABLE = 'set_your_oto_id_unavailable',
  ESIM = 'esim',
  SCAN_ID = 'scan_id',
  SCAN_ID_MBB = 'scan_id_mbb',
  ROAMING_LIMIT = 'roaming_limit',
  SHOW_FREE_TV_BOX = 'show_free_tv_box',
}

export type InformationCard = {
  value: InformationCardsEnum;
  hasCta: boolean;
  ctaButtonName?: string;
  ctaHref?: string;
};

export interface CheckoutFlow {
  productClass: string;
  deliveryDays: number;
  productFamily: string;
  steps: CheckoutStep[];
  detailsFieldsToHide: DetailsFields[];
  informationCards: InformationCard[];
}
